import React from 'react';

import "../css/firstPar.css";


const MyName  = () => {

    return (
        <div class = "mainname">
            <div id = "frontName">Khmara Arsenii</div>
            <div id = "job">Software Engineer</div>
        </div>
    )
}

export default MyName;
